import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import OptVerification from './components/Opt_Verification';
import TermAndCondition from './components/TermsAndConditions';
import GoogleCallback from './components/GoogleCallback';
import SignUp from './components/SignUp';
import Unauthorized from './components/unauthorized/unauthorized ';
import ChatMessage from './components/ChatModule/ChatMessage';
import PreviousChat from './components/ChatModule/PreviousChat';
import UserFormModal from './components/UserProfiling/UserFormModal';
import MoodTrackerModal from './components/MoodTrackerModal/MoodTrackerModal';
import UmeedAnimation from './components/UmeedAnimation/UmeedAnimation';
import Profile from './components/ChatModule/Profile';
import Report from './components/ChatModule/Report';
import Settings from './components/ChatModule/Settings';
import PrivacyPolicy from './components/ChatModule/PrivacyPolicy';
import { useSelector } from 'react-redux';

const RoutesComponent = () => {
    const { isAuthenticated, user_type } = useSelector((state) => state.auth);

    // PrivateRoute component for handling user access and role-based access
    const PrivateRoute = ({ children, allowedRoles }) => {
        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }

        if (allowedRoles && !allowedRoles.includes(user_type)) {
            // Redirect to appropriate route based on user_type
            return <Navigate to={user_type === 'superadmin' ? '/settings' : '/Unauthorized'} />;
        }

        return children;
    };


    return (
        <Router>
            <Routes>
                {/* Public Routes */}
                <Route path="/" element={<UmeedAnimation />} />
                <Route path="/login" element={<Login />} />
                <Route path="/TermAndCondition" element={<TermAndCondition />} />
                <Route path="/google-callback" element={<GoogleCallback />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/opt-verification" element={<OptVerification />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                {/* Private Routes for Users */}
                <Route
                    path="/profiling"
                    element={
                        <PrivateRoute allowedRoles={['user']}>
                            <UserFormModal />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/mood-tracker"
                    element={
                        <PrivateRoute allowedRoles={['user']}>
                            <MoodTrackerModal />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/chat-message"
                    element={
                        <PrivateRoute allowedRoles={['user']}>
                            <ChatMessage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/previous-chat"
                    element={
                        <PrivateRoute allowedRoles={['user']}>
                            <PreviousChat />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/chat-Report"
                    element={
                        <PrivateRoute allowedRoles={['user']}>
                            <Report />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <PrivateRoute allowedRoles={['user']}>
                            <Profile />
                        </PrivateRoute>
                    }
                />

                {/* Private Route for Superadmin */}
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute allowedRoles={['superadmin']}>
                            <Settings />
                        </PrivateRoute>
                    }
                />

                {/* Unauthorized Route */}
                <Route path="/Unauthorized" element={<Unauthorized />} />

                {/* Catch-all for any undefined routes */}
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </Router>
    );
};

export default RoutesComponent;
