import React, { useState, useEffect } from 'react';
import './UmeedAnimation.css';
import { useNavigate } from "react-router-dom";
import logo from '../../Public/logo-no-background.png';

const UmeedAnimation = () => {
    const navigate = useNavigate();
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setFadeOut(true);
        }, 3500);

        const redirectTimer = setTimeout(() => {
            navigate('/login');
        }, 4500);

        return () => {
            clearTimeout(timer);
            clearTimeout(redirectTimer);
        };
    }, [navigate]);

    return (
        <div className={`umeed-wrapper ${fadeOut ? 'fade-out' : ''}`}>
            <img
                src={logo}
                alt="Umeed Logo"
                className="umeed-logo animated-logo"
            />
        </div>
    );
}

export default UmeedAnimation;
