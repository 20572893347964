import axios from 'axios';
// const BASE_URL = 'https://c368-203-101-186-55.ngrok-free.app/api/v1';   // local
const BASE_URL = 'https://mykahani.pk:8443/api/v1';   // live

const apiService = axios.create({
    baseURL: BASE_URL,
});

// Request interceptor to add the token to headers
apiService.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Directly get the token from localStorage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);



export default apiService;
