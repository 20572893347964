import apiService from './apiService';

// Function to log in
export const loginUser = async (emailValue, passwordValue) => {
	try {
		const response = await apiService.post(`/login`, {
			email: emailValue,
			password: passwordValue,
		});
		return response;
	} catch (error) {
		console.error('Error logging in:', error);
		throw new Error(error?.response.data?.message);
	}
};

// Function to Register user data
export const registerUser = async (
	firstName,
	lastName,
	emailValue,
	passwordValue,
	confirmPasswordValue
) => {
	try {
		const response = await apiService.post('/register', {
			first_name: firstName,
			last_name: lastName,
			email: emailValue,
			password: passwordValue,
			password_confirmation: confirmPasswordValue,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
export const ProfilingData = async formData => {
	try {
		const response = await apiService.post('/save-profile', formData);
		return response;
	} catch (error) {
		throw error;
	}
};
export const ModeSavingData = async (selectedMood, userId, userEmail) => {
	try {
		const response = await apiService.post('/save-user-mood', {
			mood_status: selectedMood,
			user_id: userId,
			email: userEmail,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
export const SuperAdminPrompt = async formData => {
	try {
		const response = await apiService.post('/admin/save-settings', formData);
		return response;
	} catch (error) {
		throw error;
	}
};
export const GetUserProfile = async (userId, userEmail) => {
	try {
		const response = await apiService.post('/get-user-profile', {
			user_id: userId,
			email: userEmail,
		});
		return response;
	} catch (error) {
		throw error;
	}
};
export const chatMessageGPT = async ({ userId, message, conversationId }) => {
	try {
		const response = await apiService.post('/conversations/messages', {
			user_id: userId,
			message: message,
			conversation_id:conversationId,
			
			
		});
		return response;
	} catch (error) {
		throw error;
	}
};
export const oldConversationsHistory = async ({ userId, conversationId }) => {
	try {
		const response = await apiService.post('/conversations/history', {
			user_id: userId,
			conversation_id:conversationId,	
		});
		return response;
	} catch (error) {
		throw error;
	}
};
export const getAllConversation = async (userId) => {
	try {
		
		const response = await apiService.post('/conversations', {
			user_id: userId
		});
		return response;
	} catch (error) {
		throw error;
	}
};
export const OtpVerification = async (email,otp) => {
	try {
		const response = await apiService.post('/verify-otp', {email:email ,otp: otp,});
		return response;
	} catch (error) {
		throw error;
	}
};
export const ResendOTP = async (email) => {
	try {
		const response = await apiService.post('/resend-otp', {email:email});
		return response;
	} catch (error) {
		throw error;
	}
};