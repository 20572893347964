import React, { useState, useEffect } from 'react';
import ChatHeader from './ChatHeader';
import ChatSidebar from '../Sidebar/ChatSidebar';
import './ChatModule.css';
import { GetUserProfile } from '../../core/api/apiCalls';
import toast from 'react-hot-toast';
import { Card, CardContent, Typography, Grid, CircularProgress, Box } from "@mui/material";
import { EmojiEmotions, Email, Work, LocationOn, Info } from '@mui/icons-material'; // Importing Material-UI icons


const Profile = () => {
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);

    const UserProfile = async () => {
        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            const userEmail = user.email;
            setLoading(true);
            const res = await GetUserProfile(userId, userEmail);
            if (res.data.success) {
                setUserData(res?.data?.user);
            }
        } catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessages = error.response.data.errors;
                for (const [key, messages] of Object?.entries(errorMessages)) {
                    if (Array.isArray(messages)) {
                        messages?.forEach(message => {
                            toast.error(message);
                        });
                    } else {
                        toast.error(messages); // If it's a single string
                    }
                }
            } else {
                toast.error('Failed to Sign Up. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        UserProfile();
    }, []);

    const renderGender = (gender) => {
        return gender === "F" ? "👩 Female" : gender === "M" ? "👨 Male" : "❓ Unknown";
    };

    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="chat-content">
                <ChatHeader />
                <div className="chat-messages">
                    {loading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                            <CircularProgress size={50} />
                        </Box>
                    ) : (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                            <Card sx={{ boxShadow: 3, maxWidth: 800, width: "100%", padding: 4, borderRadius: 2, bgcolor: '#f9f9f9' }}>
                                <Box sx={{ backgroundColor:'#007bff', padding: "20px", borderRadius: "15px" }}>
                                    <Typography variant="h5" align="center" color="white" sx={{ fontWeight: "bold" , }}>
                                        User Profile
                                    </Typography>
                                </Box>
                                <CardContent sx={{ paddingTop: 3 }}>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <EmojiEmotions sx={{ marginRight: 1 }} /> Name:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography>
                                                {userData?.first_name} {userData?.last_name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <Email sx={{ marginRight: 1 }} /> Email:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{userData?.email}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <Info sx={{ marginRight: 1 }} /> Age:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{userData?.age}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <EmojiEmotions sx={{ marginRight: 1 }} /> Gender:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{renderGender(userData?.gender)}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <Work sx={{ marginRight: 1 }} /> Occupation:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{userData?.occupation}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <Info sx={{ marginRight: 1 }} /> Marital Status:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{userData?.marital_status}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <LocationOn sx={{ marginRight: 1 }} /> Location:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{userData?.location}</Typography>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="subtitle1" sx={{ fontWeight: "bold", display: 'flex', alignItems: 'center' }}>
                                                <EmojiEmotions sx={{ marginRight: 1 }} /> Today Mood:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">{userData?.user_mood}</Typography>
                                        </Grid>
                                    </Grid> */}
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Profile;
