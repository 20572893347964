import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { loginUser, GoogleSignIn } from "../core/api/apiCalls";
import { login } from "../core/auth/authSlice";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useMediaQuery } from '@mui/material';
import {
  Box,
  Typography,
  Grid,
  Container,
  Button,
  LinearProgress,
} from "@mui/material";
import "../App.css";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link
        color="inherit"
      // href="https://mui.com/"
      >
        mykahani.pk
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [LoadingGoogle, setLoadingGoogle] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setLoading(true);
      const res = await loginUser(values.email, values.password);

      if (res?.status === 200) {
        if (res?.data?.data?.user_type === "user") {
          dispatch(
            login({
              token: res?.data?.data?.token,
              user: res?.data?.data?.user,
              user_type:res.data.data.user_type
            })
          );
          if (res.data?.data?.is_profiled === 1) {
            // toast.success(res?.data?.message);
            navigate("/chat-message");
          } else {
            // toast.success(res?.data?.message);
            navigate("/profiling");
          }
        } else if (res?.data?.data?.user_type === "superadmin") {
          dispatch(
            login({
              token: res?.data?.data?.token,
              user: res?.data?.data?.user,
              user_type:res.data.data.user_type
            })
          );
          toast.success(res?.data?.message);
          navigate("/settings");
        }
      }
    } catch (err) {
      console.log("Failed to log in. Please try again.");
      toast.error(err.message);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };


  const HandleClickGoogle = async () => {
    try {
      setLoadingGoogle(true);
      window.location.href =
        "https://mykahani.pk:8443/login/google";
    } catch (err) {
      console.log("Failed to redirect to Google login. Please try again.");
      toast.error(err.message);
    } finally {
      setLoadingGoogle(false);
    }
  };

  // useEffect hook to handle the Google callback
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authorizationCode = params.get("code");
    const error = params.get("error");

    if (authorizationCode) {
      // Send the code to the backend to exchange for a token and user data
      fetch(
        "https://mykahani.pk:8443/login/google/callback",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code: authorizationCode }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.success) {
            // Dispatch login action to store token and user data in Redux
            dispatch(
              login({
                token: data.token,
                user: data.user,
                user_type:data.user_type
              })
            );

            // Store token and user data in localStorage
            localStorage.setItem("token", data.token);
            localStorage.setItem("user", JSON.stringify(data.user));
            if (data?.is_profiled === 1) {
              navigate("/chat-message");
            } else {
              navigate("/profiling");
            }
          } else {
            toast.error("Google login failed");
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Error during token exchange:", error);
          toast.error("Failed to log in with Google");
          navigate("/login");
        });
    } else if (error) {
      toast.error(`Google login failed: ${error}`);
      navigate("/login");
    }
  }, [location, navigate, dispatch]);
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container
        component="main"
        maxWidth="sm"
        style={{
          background: "white",
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          borderRadius: "22px",
          height: "auto",
          width: isMobile ? '350px' : '480px',
          padding: '15px',
        }}
      >
        <CssBaseline />
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, touched, errors }) => (
            <Form>
              <Box
                sx={{
                  marginTop: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 0.7, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" style={{ color: 'black' }}>
                  Sign in
                </Typography>
              </Box>
              <Box component="div" noValidate sx={{ mt: 0.6 }}>
                <Field
                  style={{
                    color: 'black', transform: 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.005)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                  as={TextField}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  size={isMobile ? "small" : "medium"}
                  helperText={touched.email && errors.email ? errors.email : ""}
                  error={touched.email && Boolean(errors.email)} // Shows red outline if email is touched and invalid
                />
                <Field
                  style={{
                    color: 'black', transform: 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                  }}
                  onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.005)'}
                  onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                  as={TextField}
                  margin="normal"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  size={isMobile ? "small" : "medium"}
                  helperText={touched.password && errors.password ? errors.password : ""}
                  error={touched.password && Boolean(errors.password)} // Shows red outline if password is touched and invalid
                />
                <FormControlLabel
                  style={{ color: "#747373f7" }}
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 1, mb: 1 }}
                  disabled={isSubmitting || Loading}
                >
                  {Loading ? "Loading..." : "Sign In 🔑"}
                </Button>
                <LinearProgress sx={{ visibility: Loading ? "" : "hidden" }} />
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "grey",
                  }}
                >
                  OR
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  sx={{ mt: 1, mb: 1 }}
                  onClick={HandleClickGoogle}
                >
                  <GoogleIcon sx={{ mr: 0.5 }} />
                  Sign In with Google
                </Button>

                <Grid container>
                  <Grid item xs>
                    </Grid>
                  <Grid item>
                    <Link
                      variant="body2"
                      onClick={() => navigate("/sign-up")}
                      style={{ cursor: "pointer" }}
                    >
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>

        <Copyright sx={{ mt: 2, mb: 1 }} />
      </Container>
    </ThemeProvider>
  );
}
