import React, { useState, useEffect } from 'react';
import ChatHeader from './ChatHeader';
import ChatSidebar from '../Sidebar/ChatSidebar';
import './ChatModule.css';
import { Card, CardContent, TextField, Button, Grid, Box, Typography, CircularProgress } from "@mui/material";
import toast from 'react-hot-toast';
import { SuperAdminPrompt } from '../../core/api/apiCalls';
const Settings = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        model: "",
        temperature: 0,
        max_tokens: 0,
        top_p: 0,
        frequency_penalty: 0,
        presence_penalty: 0,
        stop_sequence: "",
        stream: 0,
        initial_prompt: "",
        second_prompt: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'stream' ? parseInt(value) : value
        });
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const res = await SuperAdminPrompt(formData);
            if (res.data.success) {
                toast.success(res?.data?.message);
                setFormData({
                    model: "",
                    temperature: 0,
                    max_tokens: 0,
                    top_p: 0,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                    stop_sequence: "",
                    stream: 0,
                    initial_prompt: "",
                    second_prompt: ""
                });
            }
        } catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessages = error.response.data.errors;
                for (const [key, messages] of Object?.entries(errorMessages)) {

                    if (Array.isArray(messages)) {
                        messages?.forEach(message => {
                            toast.error(message);
                        });
                    } else {
                        toast.error(messages); // If it's a single string
                    }
                }
            } else {
                toast.error('Failed to Sign Up. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="chat-content">
                <ChatHeader />
                <div className="chat-messages">
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" >
                        <Card sx={{ borderRadius:'22px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', maxWidth: 600, width: "100%", padding: 3 }}>
                            <CardContent>
                                <Typography variant="h4" gutterBottom>
                                    Settings
                                </Typography>
                                <Grid container spacing={2}>
                                    {[
                                        { label: 'model', name: 'model', type: 'text' },
                                        { label: 'Temperature', name: 'temperature', type: 'number' },
                                        { label: 'Maximum Tokens', name: 'max_tokens', type: 'number' },
                                        { label: 'Top P', name: 'top_p', type: 'number' },
                                        { label: 'Frequency Penalty', name: 'frequency_penalty', type: 'number' },
                                        { label: 'Presence Penalty', name: 'presence_penalty', type: 'number' },
                                        { label: 'Stop Sequence', name: 'stop_sequence', type: 'text' },
                                        { label: 'Stream (0 or 1)', name: 'stream', type: 'number' },
                                        { label: 'Initial Prompt', name: 'initial_prompt', type: 'text' },
                                        { label: 'Second Prompt', name: 'second_prompt', type: 'text' }
                                    ].map((field, index) => (
                                        <Grid item xs={12} key={index}>
                                            <TextField
                                                fullWidth
                                                label={field.label}
                                                name={field.name}
                                                type={field.type}
                                                value={formData[field.name]}
                                                onChange={handleChange}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="center">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={loading}
                                                sx={{ minWidth: 120 }}
                                            >
                                                {loading ? <CircularProgress size={24} /> : "Submit"}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                </div>

            </div>
        </div>
    );
};

export default Settings;
