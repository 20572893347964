import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../core/auth/authSlice'; // Adjust for your Redux store
import toast from 'react-hot-toast';

const GoogleCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const handleGoogleCallback = async () => {
            const params = new URLSearchParams(location.search);
            const authorizationCode = params.get('code');
            const error = params.get('error');

            if (authorizationCode) {
                try {
                    setLoading(true);
                    const response = await fetch('https://673a-203-101-186-55.ngrok-free.app/login/google/callback', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            code: authorizationCode,
                        }),
                    });

                    const data = await response.json();

                    if (data?.token) {
                        // Store token and user data in localStorage or context
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('user', JSON.stringify(data.user));

                        // Dispatch to store in Redux if needed
                        dispatch(
                            login({
                                token: data.token,
                                user: data.user,
                            })
                        );

                        // Redirect based on whether user has completed profiling
                        if (data.is_profiled === 1) {
                            toast.success('Logged in successfully!');
                            navigate('/mood-tracker');
                        } else {
                            toast.success('Please complete your profile');
                            navigate('/profiling');
                        }
                    } else {
                        toast.error('Google login failed');
                    }
                } catch (err) {
                    console.error('Error during Google login callback:', err);
                    toast.error('Failed to log in with Google');
                } finally {
                    setLoading(false);
                }
            } else if (error) {
                toast.error(`Google login failed: ${error}`);
                navigate('/login');
            }
        };

        handleGoogleCallback();
    }, [location, navigate, dispatch]);

    return (
        <div>
            {loading ? 'Loading...' : 'Logging in with Google...'}
        </div>
    );
};

export default GoogleCallback;
