import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './Unauthorized.css';
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { logout } from "../../core/auth/authSlice";
import dynamics from 'dynamics.js';
const Unauthorized = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
    const handleConfirmLogout = () => {
        dispatch(logout());
        navigate("/");
        setOpenLogoutDialog(false);
        // toast.success("User has logged out");
    };
    useEffect(() => {
        const lock = document.querySelector('#lock');
        const key = document.querySelector('#key');

        if (!lock || !key) {
            console.error("Elements not found!");
            return;
        }

        // Animation functions
        const keyAnimate = () => {
            dynamics.animate(key, {
                translateX: 33,
            }, {
                type: dynamics.easeInOut,
                duration: 300,
                complete: lockAnimate,
            });
        };

        const lockAnimate = () => {
            dynamics.animate(lock, {
                rotateZ: -5,
                scale: 0.9,
            }, {
                type: dynamics.bounce,
                duration: 2000,
                complete: keyAnimate,
            });
        };

        // Start animation on mount
        const interval = setInterval(() => {
            keyAnimate();
        }, 3000);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);
    return (
        <div className="unauthorized">
            <div id="container_anim">
                <div id="lock" className="key-container">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="317.286 -217 248 354" width="248" height="354">
                        <g>
                            <path d="M 354.586 -43 L 549.986 -43 C 558.43 -43 565.286 -36.144 565.286 -27.7 L 565.286 121.7 C 565.286 130.144 558.43 137 549.986 137 L 354.586 137 C 346.141 137 339.286 130.144 339.286 121.7 L 339.286 -27.7 C 339.286 -36.144 346.141 -43 354.586 -43 Z" style={{ fill: '#2D5391' }} />
                            <g transform="matrix(-1,0,0,-1,543.786,70)">
                                <text transform="matrix(1,0,0,1,0,234)" style={{ fontFamily: 'Quicksand', fontWeight: 700, fontSize: '234px', fill: '#4a4444' }}>U</text>
                            </g>
                            <g transform="matrix(-1,0,0,-1,530.786,65)">
                                <text transform="matrix(1,0,0,1,0,234)" style={{ fontFamily: 'Quicksand', fontWeight: 700, fontSize: '234px', fill: '#8e8383' }}>U</text>
                            </g>
                            <path d="M 343.586 -52 L 538.986 -52 C 547.43 -52 554.286 -45.144 554.286 -36.7 L 554.286 112.7 C 554.286 121.144 547.43 128 538.986 128 L 343.586 128 C 335.141 128 328.286 121.144 328.286 112.7 L 328.286 -36.7 C 328.286 -45.144 335.141 -52 343.586 -52 Z" style={{ fill: '#4A86E8' }} />
                            <g>
                                <circle cx="441.28571428571433" cy="63.46153846153848" r="10.461538461538453" fill="black" />
                                <rect x="436.055" y="66.538" width="10.462" height="34.462" fill="black" />
                            </g>
                        </g>
                    </svg>
                </div>

                <div id="key">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="232.612 288.821 169.348 109.179" width="169.348" height="109.179">
                        <g>
                            <path d=" M 382.96 349.821 L 368.96 349.821 L 368.96 314.821 L 382.96 307.821 L 382.96 349.821 Z " fill="rgb(55,49,49)" />
                            <path d=" M 292.134 354.827 L 379.96 315.39 L 379.96 305.547 L 292.134 343.094 L 292.134 354.827 Z " fill="rgb(55,49,49)" />
                            <path d=" M 280.96 340.109 L 401.96 288.821 L 401.96 340.109 L 382.96 349.972 L 382.96 308.547 L 265.96 360.821 L 259.96 349.972 L 280.96 340.109 Z " fill="rgb(115,102,102)" />
                            <path d=" M 401.96 288.821 L 382.96 288.821 L 280.96 332.821 L 292.134 340.109 L 401.96 288.821 Z " fill="rgb(115,102,102)" />
                            <g>
                                <path d=" M 232.755 354.125 C 230.958 328.501 246.297 306.519 266.988 305.068 C 287.679 303.617 305.937 323.243 307.734 348.867 C 309.531 374.492 294.191 396.473 273.5 397.924 C 252.809 399.375 234.552 379.75 232.755 354.125 Z " fill="rgb(55,49,49)" />
                                <path d=" M 239.241 352.316 C 237.564 328.406 252.144 307.876 271.779 306.499 C 291.414 305.122 308.716 323.416 310.393 347.326 C 312.07 371.236 297.49 391.766 277.855 393.143 C 258.22 394.52 240.917 376.226 239.241 352.316 Z " fill="rgb(115,102,102)" />
                                <path d=" M 260.038 353.084 C 259.196 348.171 261.788 343.621 265.822 342.929 C 269.856 342.238 273.816 345.665 274.658 350.578 C 275.5 355.49 272.909 360.041 268.874 360.732 C 264.84 361.424 260.88 357.997 260.038 353.084 Z " fill="salmon" />
                            </g>
                        </g>
                    </svg>
                </div>

            </div>
            <p style={{ color: 'black', fontWeight: 'bold' }}>You do not have permission to view this page.</p>
            {/* <Link to="/login">Go to Login</Link> */}
            <Button onClick={handleConfirmLogout} variant="contained">Logout</Button>
        </div>
    );
};

export default Unauthorized;
