import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import './umeedword.css';
import { getAllConversation } from '../../core/api/apiCalls';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../ContextApi/AppProvider';
import logo from '../../Public/logo-no-background.png'
import { Avatar, } from '@mui/material';
const ChatSidebar = () => {
    const location = useLocation(); // Get the current route location
    const { isSidebarOpen, isMobileView, toggleSidebar, setSidebarOpen, setIsMobileView } = useContext(AppContext);
    const [showConversations, setShowConversations] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [activeConversationId, setActiveConversationId] = useState(null);
    const navigate = useNavigate()
    const formatDate = (dateString) => {
        return dayjs(dateString).format('DD/MMM');
    };

    const toggleConversations = () => {
        setShowConversations(!showConversations);
    };

    // const toggleSidebar = () => {
    //     setSidebarOpen(!isSidebarOpen);
    // };
    const getConversation = async () => {

        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;

            const res = await getAllConversation(userId);
            if (res.data.success) {
                setConversations(res?.data?.conversations.reverse());
            };

        } catch (error) {
            console.error('Failed to received chat history. Please try again.');
        }
    }

    useEffect(() => {
        // Call the API once immediately
        getConversation();
        const intervalId = setInterval(() => {
            getConversation();
        }, 10000); // 10 seconds   
        return () => clearInterval(intervalId);  // Clean up the interval when the component is unmounted
    }, []);

    // Update isMobileView state based on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 600);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const HandleConversationId = (conversationIdOld) => {
        setActiveConversationId(conversationIdOld);  // Set the active conversation
        navigate(`/previous-chat`, { state: { conversationIdOld } });
    };


    // Determine if a path is selected based on the current route
    const isSelected = (path) => location.pathname === path;

    // Determine the style for a selected item
    const getItemStyle = (path) => ({
        backgroundColor: isSelected(path) ? '#007bff' : 'transparent', // Change the color as needed
        padding: '5px',
        boxShadow: isSelected(path) ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : 'none',
        borderRadius: isSelected(path) ? '12px' : '1px',
        color: isSelected(path) ? 'white' : 'black',
        // color:'white'
    });
    const getItemStyleHistory = (path, conversationIdOld) => ({
        backgroundColor: activeConversationId === conversationIdOld ? '#e0f7fa' : 'transparent',
        boxShadow: conversationIdOld ? 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' : 'none',
        borderRadius: conversationIdOld ? '12px' : '1px',

    });

    const letters = ['M', 'Y', '-', 'K', 'A', 'H', 'A', 'N', 'I'];
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : {};
    const UserType = user.role;
    return (
        <>
            <div  style={{height:'100vh'}}className={`chat-sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <div style={{ padding: '3.7px', borderBottom: '1px solid #ccc', textAlign: 'center' }}>
                    <div className="umeedWord" style={{ display: isMobileView ? 'none' : 'block' }}>
                        <img
                            alt="Remy Sharp"
                            src={logo}
                            style={{ height: '50px' }}
                        />
                    </div>
                </div>
                <nav style={{ padding: '21px' }}>
                    <ul style={{ listStyle: 'none', padding: 0, marginTop: isMobileView ? '50px' : '-11px', }}>
                        {UserType === '1' && (
                            <li style={{ marginBottom: '10px', marginTop: isMobileView ? '70px' : '-11px', width: isMobileView ? 'auto' : '200px', }}>
                                <Link
                                    to="/settings"
                                    style={{ ...linkStyle, ...getItemStyle('/settings'), cursor: 'pointer' }}
                                    onClick={() => {
                                        setSidebarOpen(false);  // Close sidebar when clicked
                                    }}
                                >
                                    <IconButton><SettingsIcon /></IconButton>
                                    Settings
                                </Link>
                            </li>
                        )}
                        {UserType === '0' && (
                            <>
                                <li style={{ marginBottom: '10px', width: isMobileView ? 'auto' : '210px', }}>
                                    <Link
                                        to="/chat-message"
                                        style={{ ...linkStyle, ...getItemStyle('/chat-message'), cursor: 'pointer' }}
                                        onClick={() => {
                                            setSidebarOpen(false);  // Close sidebar when clicked
                                        }}
                                    >
                                        <IconButton><ChatIcon /></IconButton>
                                        New Chat
                                    </Link>
                                </li>
                                <li style={{ marginBottom: '10px', }}>
                                    <Link
                                        to="/profile"
                                        style={{ ...linkStyle, ...getItemStyle('/profile') }}
                                        onClick={() => {
                                            setSidebarOpen(false);  // Close sidebar when clicked
                                        }}
                                    >
                                        <IconButton><AccountCircleIcon /></IconButton>
                                        Profile
                                    </Link>
                                </li>

                                <li style={{ marginBottom: '2px' }}>
                                    <Link
                                        style={{ ...linkStyle, ...getItemStyle(''), cursor: 'pointer' }}
                                        onClick={() => {
                                            toggleConversations(); // Toggle the state
                                        }}
                                    >
                                        <IconButton>
                                            {showConversations ? <FilterListOffIcon /> : <FilterListIcon />}
                                        </IconButton>
                                        {showConversations ? 'Hide Conversations' : 'Show Conversations'}
                                    </Link>
                                </li>
                                {showConversations && (
                                    <ul style={{ listStyleType: 'none', padding: '4px', fontSize: '14px', maxHeight: '45vh', overflowY: 'auto', marginTop: '8px' }}>
                                        {conversations?.map((conversation, index) => (
                                            <li
                                                key={index}
                                                style={{
                                                    ...getItemStyleHistory(conversation?.conversation_id),  // Dynamically apply background color based on activeConversationId
                                                    marginBottom: '12px',  // Increased spacing between items for a clean look
                                                    fontSize: '12px',
                                                    color: '#333',  // Darker text color for better readability
                                                    display: 'flex',
                                                    justifyContent: 'space-between',  // Better spacing between title and date
                                                    cursor: 'pointer',
                                                    alignItems: 'center',  // Vertically center items
                                                    padding: '12px',  // Padding for a more clickable area
                                                    backgroundColor: activeConversationId === conversation?.conversation_id ? '#e0f7fa' : '#fff',  // Active conversation color  
                                                    borderRadius: '8px',  // Rounded corners for a modern look
                                                    transition: 'background-color 0.3s, box-shadow 0.3s',  // Smooth hover effects
                                                    width: isMobileView ? '300px' : '160px',
                                                }}
                                                onClick={() => {
                                                    HandleConversationId(conversation?.conversation_id);
                                                    setSidebarOpen(false);  // Close sidebar after selecting conversation
                                                }}
                                            >
                                                <strong style={{ flex: 1 }}>{conversation?.title}</strong>
                                                <span style={{ fontSize: '12px', color: '#999' }}>
                                                    {formatDate(conversation?.created_at)}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </>
                        )}
                    </ul>
                </nav>
            </div>

        </>
    );
};

// Styles for the links
const linkStyle = {
    textDecoration: 'none',
    color: '#333',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '500',
    padding: '10px',
    borderRadius: '4px',
    transition: 'background-color 0.3s ease',
};

export default ChatSidebar;
