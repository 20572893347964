import React, { createContext, useState, useEffect } from 'react';
export const AppContext = createContext();
export const AppProvider = ({ children }) => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 600);
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    // Toggle sidebar function
    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Handle window resize to update `isMobileView`
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <AppContext.Provider value={{ isMobileView, isSidebarOpen, toggleSidebar,setSidebarOpen ,setIsMobileView}}>
            {children}
        </AppContext.Provider>
    );
};
