import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { SentimentVerySatisfied, SentimentSatisfied, SentimentNeutral, SentimentDissatisfied, EmojiEmotions } from '@mui/icons-material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { ModeSavingData } from '../../core/api/apiCalls';
function MoodTrackerModal() {
    const [open, setOpen] = useState(true);
    const [selectedMood, setSelectedMood] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleMoodClick = (mood) => {
        setSelectedMood(mood);
    };

    const moods = [
        
        { label: "angry", icon: SentimentVeryDissatisfiedIcon, color: "#F44336" } ,  
        { label: "sad", icon: SentimentDissatisfied, color: "#FF5722" },
        { label: "neutral", icon: SentimentNeutral, color: "#FFC107" },  
        { label: "happy", icon: SentimentSatisfied, color: "#8BC34A" },          // Light Green
        { label: "very happy", icon: SentimentVerySatisfied, color: "#4CAF50" }, // Green 
    ];

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            const userEmail = user.email;
            const res = await ModeSavingData(selectedMood, userId, userEmail);
            if(res.data.success){  
                toast.success(res.data.message);
                navigate('/chat-message');
            }
            
        }catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessages = error.response.data.errors; 
                for (const [key, messages] of Object?.entries(errorMessages)) {
            
                    if (Array.isArray(messages)) {
                        messages?.forEach(message => {
                            toast.error(message);
                        });
                    } else {
                        toast.error(messages); // If it's a single string
                    }
                }
            } else {
                toast.error('Failed to Sign Up. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <DialogTitle align="center" sx={{ fontWeight: 'bold', fontSize: { xs: '1.5rem', md: '2rem' } }}>
                How Are You Feeling Today?
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '10px',
                    flexWrap: 'wrap'
                }}>
                    {moods.map((mood, index) => {
                        const IconComponent = mood.icon;
                        return (
                            <IconComponent
                                key={index}
                                sx={{
                                    fontSize: { xs: 60, sm: 70, md: 80 },
                                    cursor: 'pointer',
                                    color: selectedMood === mood.label ? mood.color : 'grey'
                                }}
                                onClick={() => handleMoodClick(mood?.label)}
                            />
                        );
                    })}
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '8px',
                    flexWrap: 'wrap',
                    marginTop: '20px'
                }}>
                    {moods.map((mood, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            sx={{
                                backgroundColor: selectedMood === mood.label ? mood.color : '#9fa0a0',
                                color: 'white',
                                fontWeight: selectedMood === mood.label ? 'bold' : 'normal',
                                fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }
                            }}
                            onClick={() => handleMoodClick(mood.label)}
                        >
                            {mood.label}
                        </Button>
                    ))}
                </div>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant="outlined" disabled={loading} onClick={handleSubmit} sx={{ fontWeight: 'bold', fontSize: { xs: '0.8rem', md: '1rem' } }}>
                    {loading ? <CircularProgress size={24} /> : "Continue"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default MoodTrackerModal;
