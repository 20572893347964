import React, { useState, useEffect, useContext ,useRef} from 'react';
import { MessageBox } from 'react-chat-elements';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import ChatSidebar from '../Sidebar/ChatSidebar';
import 'react-chat-elements/dist/main.css';
import { AppContext } from "../ContextApi/AppProvider";
import './ChatModule.css';
import toast from 'react-hot-toast';
import { chatMessageGPT } from '../../core/api/apiCalls';

const emotions = [
    { label: 'Depressed', icon: '😔' },
    { label: 'Sad', icon: '😢' },
    { label: 'Angry', icon: '😡' },
    { label: 'Neutral', icon: '😐' },
    { label: 'Happy', icon: '😊' },
    { label: 'Excited', icon: '😄' },
];

const ChatModule = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conversationId, SetConversationId] = useState('');
    const [loggedInUser] = useState({ name: 'John Doe', profileImage: '/path/to/image.jpg' });
    const [isTyping, setIsTyping] = useState(false);
    const [showEmotions, setShowEmotions] = useState(true); // Show emotions initially
    const [selectedEmotion, setSelectedEmotion] = useState('');
    const { isSidebarOpen, isMobileView, toggleSidebar, setSidebarOpen, setIsMobileView } = useContext(AppContext);

    const messagesEndRef = useRef(null); // Reference for scrolling to bottom

    // Function to scroll to the bottom of the chat
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' ,inline: "nearest"});
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, isTyping]); // Scroll to bottom on new messages or typing indicator
    // Function to create typewriter effect
    const typeOutMessage = (text, delay = 60) => {

        return new Promise((resolve) => {
            let index = -1;

            // Use a recursive function for better control over state updates
            const typeCharacter = () => {

                if (index < text.length) {
                    setMessages((prevMessages) => {
                        const lastMessage = prevMessages[prevMessages.length - 1];
                        const updatedText = (lastMessage.text || '') + text.charAt(index);

                        return [
                            ...prevMessages.slice(0, -1), // Exclude the last partial message
                            { ...lastMessage, text: updatedText }, // Update the last message with the new character
                        ];
                    });

                    index += 1;

                    // Continue typing after the specified delay
                    setTimeout(typeCharacter, delay);
                } else {
                    resolve(); // Resolve the promise when the entire message is typed out
                }
            };

            // Start typing the characters
            typeCharacter();
        });
    };

    // Function to send message
    const handleSendMessage = async (message) => {
        // Concatenate emotion with message if an emotion is selected
        const messageWithEmotion = selectedEmotion
            ? `i am feeling  ${selectedEmotion.toLowerCase()} today`
            : message;

        const newMessage = {
            position: 'right',
            type: 'text',
            text: messageWithEmotion,
            date: new Date(),
        };

        // Update UI immediately with user's message
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        // Hide emotions after the first message is sent
        setShowEmotions(false);

        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            setIsTyping(true);

            const payload = {
                userId,
                message: messageWithEmotion,
                conversationId: conversationId || null, // Include conversationId if available
            };

            // Send the user's message to the API
            const res = await chatMessageGPT(payload);

            if (res.data.success) {
                if (!conversationId) {
                    SetConversationId(res.data.message.user_message?.conversation_id);
                }

                const chatgptResponse = res.data.message.chatgpt_response.message;

                // Append an empty message to the UI (this will be typed out later)
                const botResponse = {
                    position: 'left',
                    type: 'text',
                    text: '',
                    date: new Date(),
                };

                // Add an empty message to be typed out
                setMessages((prevMessages) => [...prevMessages, botResponse]);

                // Type out the message gradually
                await typeOutMessage(chatgptResponse, 60);
                setSelectedEmotion('')
                setIsTyping(false);
            }
        } catch (error) {
            toast.error('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
            setIsTyping(false);
        }
    };

    useEffect(() => {
        // Predefined welcome message
        const welcomeMessage = {
            position: 'left',
            type: 'custom', // Custom message type to handle JSX
            text: (
                <>
                    Assalamualaikum, I am Sarah and I am here to help you. You can start conversation in the language that you prefer. Can you tell me how are you feeling?
                    <br />
                    <br />
                    السلام علیکم، میں سارہ ہوں اور میں آپ کی مدد کے لیے حاضر ہوں۔ آپ اپنی پسند کی زبان میں گفتگو شروع کر سکتے ہیں۔ کیا آپ مجھے بتا سکتے ہیں کہ آپ کیسا محسوس کر رہے ہیں؟
                </>
            ),
            date: new Date(),
        };

        // Add the welcome message if not already there
        setMessages((prevMessages) => {
            if (!prevMessages.some((msg) => msg.text === welcomeMessage.text)) {
                return [...prevMessages, welcomeMessage];
            }
            return prevMessages;
        });
    }, []);

    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="chat-content">
                <ChatHeader user={loggedInUser} isSidebarOpen={isSidebarOpen} />
                {!isSidebarOpen &&
                    <>
                        <div className="chat-messages" style={{ overflowY: 'auto', flexGrow: 1 }}>
                            {messages?.map((msg, index) => (
                                <MessageBox
                                    key={index}
                                    position={msg.position}
                                    type={msg.type === 'custom' ? 'text' : msg.type}
                                    text={msg.type === 'custom' ? msg.text : msg.text}
                                    date={msg.date}
                                    className={msg.position === 'right' ? 'sent-message' : 'received-message'}
                                />
                            ))}
                        

                            {/* Show animated typing indicator when bot is responding */}
                            {isTyping && (
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            )}
                            <div ref={messagesEndRef} />
                        </div>

                        {showEmotions && (
                            <div className="emotion-selector">
                                {emotions?.map((emotion) => (
                                    <div
                                        key={emotion.label}
                                        className={`emotion-item ${selectedEmotion === emotion.label ? 'selected' : ''}`}
                                        onClick={() => {
                                            setSelectedEmotion(emotion.label);
                                            handleSendMessage(`I am feeling ${emotion.label.toLowerCase()} today.`);
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            marginRight: '10px',
                                            background: '#007bff',
                                            color: 'white',
                                            borderRadius: '30px',
                                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                            transform: 'scale(1)',
                                            transition: 'transform 0.3s ease-in-out',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '10px 15px', // Balanced padding for center alignment
                                            // Ensure same width for each item
                                            textAlign: 'center'
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                                        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                    >
                                        <span style={{ fontSize: '20px' }}>{emotion.icon}</span>&nbsp;
                                        <span style={{ fontWeight: 'bold' }}>{emotion.label}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>

                }

                {/* Conditionally render ChatInput */}
                {!isSidebarOpen && <ChatInput onSend={handleSendMessage} />}
            </div>
        </div>
    );
};

export default ChatModule;
