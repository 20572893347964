import React, { useState, useEffect } from 'react';
import ChatHeader from './ChatHeader';
import ChatSidebar from '../Sidebar/ChatSidebar';
import './ChatModule.css'; 

const Report = () => {
    

    return (
        <div className="chat-container">
            <ChatSidebar/>
            <div className="chat-content">
                <ChatHeader  />
                <div className="chat-messages">
                    hello
                </div>
            
            </div>
        </div>
    );
};

export default Report;
