import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import {
    Button,
    Box,
    Typography,
    Stack,
    CircularProgress
} from '@mui/material';
import toast from 'react-hot-toast';
import { OtpVerification, ResendOTP } from '../core/api/apiCalls';
import { useMediaQuery } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const Opt_Verification = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(600); // 2 minutes
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const location = useLocation();
    const email = location.state?.email;

    // Handle countdown timer
    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
        } else {
            setIsResendDisabled(false);
        }
        return () => clearInterval(countdown);
    }, [timer]);

    const handleResend = async (email) => {
        setIsResendLoading(true);
        try {
            const res = await ResendOTP(email);
            toast.success('OTP resent successfully')
            setOtp('');
            setTimer(600);
            setIsResendDisabled(true);
        } catch (error) {
            toast.error('Resend Error , Some thing is wrong.');
        } finally {
            setIsResendLoading(false);
        }
        ;
    };

    const handleVerify = async () => {
        if (!/^\d+$/.test(otp)) {
            toast.error('OTP should contain only numbers');
            return;
        }
        if (otp.length === 4) {
            setIsLoading(true);
            try {
                const res = await OtpVerification(email, otp);
                toast.success('OTP verified successfully');
                navigate('/login');
            } catch (error) {
                toast.error('Invalid OTP');
            } finally {
                setIsLoading(false);
            }
        } else {
            toast.error('Please enter a valid 4-digit OTP');
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                height: isMobile ? '45vh' : '50vh',
                width: isMobile ? '45vh' : '50vh',
                padding: '2rem',
                background: 'white',
                borderRadius: '22px',
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
            }}
        >
            <Typography variant="h5" fontWeight="bold" mb={2} style={{ color: 'grey' }}>
                OTP Verification
            </Typography>

            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                style={{ fontSize: '18px' }}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                    width: '3rem',
                    height: '3.5rem',
                    fontSize: '1.5rem',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    textAlign: 'center'
                }}
            />

            <Stack direction="row" alignItems="center" spacing={1} mb={2} mt={2}>
                <Typography variant="body1" color="textSecondary">
                    {`Time remaining: ${Math.floor(timer / 60)
                        .toString()
                        .padStart(2, '0')}:${(timer % 60).toString().padStart(2, '0')}`}
                </Typography>
            </Stack>

            <Button
                variant="contained"
                color="primary"
                onClick={handleVerify}
                disabled={otp.length !== 4 || timer === 0 || isLoading}
                sx={{
                    width: '80%',
                    maxWidth: '300px',
                    padding: '0.75rem',
                    fontSize: '1rem',
                    borderRadius: '12px',
                    mb: 2
                }}
            >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
            </Button>

            {/* <Button
                variant="outlined"
                color="secondary"
                onClick={() => handleResend(email)}
                disabled={isResendDisabled || isResendLoading}
                sx={{
                    width: '80%',
                    maxWidth: '300px',
                    padding: '0.75rem',
                    fontSize: '1rem',
                    borderRadius: '12px'
                }}
            >
                {isResendLoading ? <CircularProgress size={24} color="inherit" /> : 'Resend OTP'}

            </Button> */}
        </Box>
    );
};

export default Opt_Verification;
