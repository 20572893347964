import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
    isAuthenticated: !!localStorage.getItem('token'), // Check token in localStorage
    user: JSON.parse(localStorage.getItem('user')) || null,
    user_type: localStorage.getItem('user_type') || null, // Add user_type to initial state
};

// Create slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            const { token, user, user_type } = action.payload;
            state.isAuthenticated = true;
            state.user = user;
            state.user_type = user_type; // Update Redux state
            localStorage.setItem('token', token);
            localStorage.setItem('user_type', user_type); // Save user_type in localStorage
            localStorage.setItem('user', JSON.stringify(user));
        },
        logout(state) {
            state.isAuthenticated = false;
            state.user = null;
            state.user_type = null; // Clear user_type in Redux state
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('user_type'); // Clear user_type from localStorage
            localStorage.clear();
        },

    },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
