import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <Container maxWidth="md" sx={{ padding: '20px', marginTop: '20px', backgroundColor: '#f4f6f8', borderRadius:'22px', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}>
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Terms and Conditions
                </Typography>
            </Box>

            {/* Introduction Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Introduction
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    Welcome to our website. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use the website if you do not agree to all the terms and conditions stated on this page.
                </Typography>
            </Box>

            {/* License Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    License to Use
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    Unless otherwise stated, we own the intellectual property rights for all material on this website. You may view, download for caching purposes, and print pages from the website for your personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
                </Typography>
                <List style={{ color: 'black' }}>
                    <ListItem>
                        <ListItemText primary="You must not republish material from this website." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="You must not sell, rent, or sub-license material from this website." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="You must not reproduce, duplicate, copy or otherwise exploit material on this website for commercial purposes." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="You must not edit or otherwise modify any material on the website." />
                    </ListItem>
                </List>
            </Box>

            {/* User Conduct Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    User Conduct
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    As a condition of use, you promise not to use the services for any purpose that is unlawful or prohibited by these terms, or any other purpose not reasonably intended by us. Examples of unacceptable conduct include, but are not limited to:
                </Typography>
                <List style={{ color: 'black' }}>
                    <ListItem>
                        <ListItemText primary="Harassing, threatening, or defrauding users or employees of the service." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Sending spam or unsolicited messages." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Impersonating any person or entity, or falsely stating or otherwise misrepresenting yourself." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Distributing viruses, trojans, or other harmful software." />
                    </ListItem>
                </List>
            </Box>

            {/* Limitation of Liability Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Limitation of Liability
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    In no event will we, or our suppliers or licensors, be liable with respect to any subject matter of this agreement under any contract, negligence, strict liability or other legal or equitable theory for:
                </Typography>
                <List style={{ color: 'black' }}>
                    <ListItem>
                        <ListItemText primary="Any special, incidental, or consequential damages." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="The cost of procurement for substitute products or services." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Interruption of use or loss or corruption of data." />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Any amounts that exceed the fees paid by you to us under this agreement during the twelve (12) month period prior to the cause of action." />
                    </ListItem>
                </List>
            </Box>

            {/* Governing Law Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Governing Law
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    These terms and conditions are governed by and construed in accordance with the laws of [Insert Jurisdiction], and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
                </Typography>
            </Box>
            {/* Termination Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Termination
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    We may terminate or suspend access to our services immediately, without prior notice or liability, for any reason whatsoever, including, but not limited to, breach of these terms. All provisions of the terms which by their nature should survive termination shall survive, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                </Typography>
            </Box>

            {/* Changes to Terms Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Changes to These Terms
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                </Typography>
            </Box>

            {/* Contact Information Section */}
            <Box sx={{ marginBottom: '20px' }}>
                <Typography variant="h5" component="h2" gutterBottom sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                    Contact Information
                </Typography>
                <Typography variant="body1" sx={{ color: '#555' }}>
                    If you have any questions about these Terms, please contact us at [Insert Contact Information].
                </Typography>
            </Box>
        </Container>
    );
};

export default TermsAndConditions;
