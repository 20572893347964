import React, { useState } from 'react';
import {
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Container,
    LinearProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { registerUser } from '../core/api/apiCalls';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useMediaQuery } from '@mui/material';

import * as Yup from 'yup';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit">mykahani.pk</Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Paper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
}));

const validationSchema = Yup.object({
    firstName: Yup.string()
        .required('First Name is required'),
    lastName: Yup.string()
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    termsAndConditions: Yup.boolean()
        .oneOf([true], 'You must accept the terms and conditions'),
});

export default function SignUp() {
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setLoading(true);
            const res = await registerUser(
                values.firstName,
                values.lastName,
                values.email,
                values.password,
                values.confirm_password
            );
            if (res.data.success) {
                // toast.success('OTP sent! Please check your email.');
                // navigate('/opt-verification', { state: { email: values.email } });
                toast.success('Sign Up Successfully');
                navigate('/login');
            }
        } catch (error) {
            if (error?.response?.data?.errors) {
                const errorMessages = error.response.data.errors; 
                for (const [key, messages] of Object?.entries(errorMessages)) {
            
                    if (Array.isArray(messages)) {
                        messages?.forEach(message => {
                            toast.error(message);
                        });
                    } else {
                        toast.error(messages); // If it's a single string
                    }
                }
            } else {
                toast.error('Failed to Sign Up. Please try again.');
            }
        } finally {
            setLoading(false);
            setSubmitting(false);
        }
    };

    const handleTermsClick = () => {
        window.open('/TermAndCondition', '_blank'); // Opens the terms page in a new tab
    };
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Container component="main" maxWidth="sm" style={{
            background: 'white',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            padding: '15px', borderRadius: '22px', height: 'auto',width: isMobile ? '350px' : '480px'
        }}>
            <CssBaseline />
            <Paper style={{marginTop:'8px'}}>
                <StyledAvatar sx={{ bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </StyledAvatar>
                <Typography component="h1" variant="h5" style={{
                    color: 'black', transform: 'scale(1)',
                    transition: 'transform 0.3s ease-in-out',
                    marginBottom:'10px'
                }}
                    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                >
                    Sign up
                </Typography>

                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        confirm_password: '',
                        termsAndConditions: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, touched, errors, handleChange, values }) => (
                        <Form noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="firstName"
                                    variant="outlined"
                                    fullWidth
                                    label="First Name"
                                    size={isMobile ? "small" : "medium"} // Smaller text field on mobile
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={<ErrorMessage name="firstName" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="lastName"
                                    variant="outlined"
                                    fullWidth
                                    label="Last Name"
                                    size={isMobile ? "small" : "medium"} // Smaller text field on mobile
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={<ErrorMessage name="lastName" />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    name="email"
                                    variant="outlined"
                                    fullWidth
                                    label="Email Address"
                                    size={isMobile ? "small" : "medium"} // Smaller text field on mobile
                                    error={touched.email && Boolean(errors.email)}
                                    helperText={<ErrorMessage name="email" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="password"
                                    variant="outlined"
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    size={isMobile ? "small" : "medium"} // Smaller text field on mobile
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={<ErrorMessage name="password" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field
                                    as={TextField}
                                    name="confirm_password"
                                    variant="outlined"
                                    fullWidth
                                    label="Confirm Password"
                                    type="password"
                                    size={isMobile ? "small" : "medium"} // Smaller text field on mobile
                                    error={touched.confirm_password && Boolean(errors.confirm_password)}
                                    helperText={<ErrorMessage name="confirm_password" />}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Field as={Checkbox} name="termsAndConditions" color="primary" />
                                    }
                                    label=""
                                />
                                <Typography style={{ color: "#1976d2", marginTop: '10px', cursor: 'pointer' }} onClick={handleTermsClick}>
                                    I accept all terms and conditions.
                                    <ErrorMessage name="termsAndConditions" component="div" style={{ color: 'red', marginLeft: '10px', fontSize: '8px' }} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <SubmitButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting || Loading}
                        >
                            {Loading ? 'loading...' : 'Sign Up'}
                        </SubmitButton>
                        <LinearProgress sx={{ visibility: Loading ? '' : 'hidden' }} />
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link variant="body2" onClick={() => navigate('/login')} style={{ cursor: "pointer" }}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Form>
                    )}
                </Formik>
            </Paper>
            <Box mt={2.5}>
                <Copyright />
            </Box>
        </Container>
    );
}
