import React, { useState } from 'react';
import { Input } from 'react-chat-elements';
import { Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatInput = ({ onSend }) => {
    const [message, setMessage] = useState('');

    const handleSend = () => {
        if (message.trim()) {
            onSend(message);
            setMessage('');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '10px',
                backgroundColor: '#ffffff',
                borderTop: '1px solid #ccc',
                position: 'sticky',
                bottom: 0, // Stick to the bottom of the screen
                // width: '100%',
                zIndex: 1000, // Ensure it's above other content
            }}
        >
            <Input
                style={{ fontSize: '18px', flex: 1 }}
                placeholder="Type a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            />
            <SendIcon
                style={{ color: '#4e4b4b', fontSize: '24px', cursor: 'pointer', marginTop: '8px',marginLeft: '-26px' }}
                onClick={handleSend}
            />
        </Box>
    );
};

export default ChatInput;
