import React, { useState, useEffect,useRef } from 'react';
import { MessageBox } from 'react-chat-elements';
import ChatHeader from './ChatHeader';
import ChatInput from './ChatInput';
import ChatSidebar from '../Sidebar/ChatSidebar';
import 'react-chat-elements/dist/main.css';
import './ChatModule.css';
import toast from 'react-hot-toast';
import {CircularProgress, Box } from "@mui/material";
import { chatMessageGPT, oldConversationsHistory } from '../../core/api/apiCalls';
import Umeed from '../../Public/Umeed.jpg';
import sarah from '../../Public/sarah.png';
import { useLocation, useParams } from 'react-router-dom';


const PreviousChat = () => {
    const location = useLocation();
    const { conversationIdOld } = location.state || {};
    const [messages, setMessages] = useState([]); // Initialize as an empty array
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [conversationId, SetConversationId] = useState(conversationIdOld || ''); // Use the passed conversationId
    const [loggedInUser] = useState({ name: 'John Doe', profileImage: '/path/to/image.jpg' });
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null); // Reference for scrolling to bottom

    // Function to scroll to the bottom of the chat
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' ,inline: "nearest"});
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages, isTyping]); // Scroll to bottom on new messages or typing indicator

    const typeOutMessage = (text, delay = 60) => {

        return new Promise((resolve) => {
            let index = -1;

            // Use a recursive function for better control over state updates
            const typeCharacter = () => {

                if (index < text.length) {
                    setMessages((prevMessages) => {
                        const lastMessage = prevMessages[prevMessages.length - 1];
                        const updatedText = (lastMessage.text || '') + text.charAt(index);

                        return [
                            ...prevMessages.slice(0, -1), // Exclude the last partial message
                            { ...lastMessage, text: updatedText }, // Update the last message with the new character
                        ];
                    });

                    index += 1;

                    // Continue typing after the specified delay
                    setTimeout(typeCharacter, delay);
                } else {
                    resolve(); // Resolve the promise when the entire message is typed out
                }
            };

            // Start typing the characters
            typeCharacter();
        });
    };
    // Function to send message
    const handleSendMessage = async (message) => {
        // User's message
        const newMessage = {
            position: 'right',
            type: 'text',
            text: message,
            date: new Date(),
        };

        // Update UI immediately with user's message
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            setIsTyping(true);

            const payload = {
                userId,
                message,
                conversationId: conversationId || null, // Include conversationId if available
            };

            // Send the user's message to the API
            const res = await chatMessageGPT(payload);

            if (res.data.success) {
                if (!conversationId) {
                    SetConversationId(res.data.message.user_message?.conversation_id);
                }

                const chatgptResponse = res.data.message.chatgpt_response.message;

                // Append an empty message to the UI (this will be typed out later)
                const botResponse = {
                    position: 'left',
                    type: 'text',
                    text: '', // Start with an empty string
                    date: new Date(),
                };

                // Add an empty message to be typed out
                setMessages((prevMessages) => [...prevMessages, botResponse]);

                // Type out the message gradually
                await typeOutMessage(chatgptResponse, 50);

                setIsTyping(false);
            }
        } catch (error) {
            toast.error('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
            setIsTyping(false);
        }
    };

    const HandleConversationId = async (conversationIdOld) => {
        try {
            const storedUser = localStorage.getItem('user');
            const user = storedUser ? JSON.parse(storedUser) : {};
            const userId = user.id;
            const payload = {
                userId,
                conversationId: conversationIdOld || null,
            };
            setIsLoading(true)
            const res = await oldConversationsHistory(payload);
            if (res.data.success) {
                // Assuming res.data.messages contains the messages array
                const messages = res?.data?.messages?.map((message) => ({
                    position: message?.sender_type === 'user' ? 'right' : 'left',
                    type: 'text',
                    text: message.message,
                    date: new Date(message.created_at),
                }));
                setMessages(messages); // Update the state with the fetched messages
            }
        } catch (error) {
            toast.error('Failed to received message. Please try again.');
        }finally{
            setIsLoading(false)
        }
    }
    useEffect(() => {
        
        if (conversationIdOld) {
            HandleConversationId(conversationIdOld); // Call this function when the component mounts or when conversationIdOld changes
        }
    }, [conversationIdOld]);

    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="chat-content">
                <ChatHeader user={loggedInUser} />
                <div className="chat-messages" style={{ overflowY: 'auto', flexGrow: 1 }}>
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                            <CircularProgress size={50} />
                        </Box>
                    ) : (
                        messages?.map((msg, index) => (
                            <MessageBox
                                key={index}
                                position={msg.position}
                                type={msg.type}
                                text={msg.text}
                                date={msg.date}
                                className={msg.position === 'right' ? 'sent-message' : 'received-message'}
                            />
                        ))
                    )}
    
                    {/* Show animated typing indicator when the bot is responding */}
                    {isTyping && (
                        <div className="typing-indicator">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <ChatInput onSend={handleSendMessage} />
            </div>
        </div>
    );
    
};

export default PreviousChat;
