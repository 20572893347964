import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client' instead of 'react-dom'
import './index.css';
import App from './App';
import { store } from './core/app/store';
import { Provider } from 'react-redux';

// Create a root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render your application
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
