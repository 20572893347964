import React, { useState, useContext } from "react";
import { Dialog, Box, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AppContext } from "../ContextApi/AppProvider";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { logout } from "../../core/auth/authSlice";
import logo from "../../Public/Umeed.jpg";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import { styled } from '@mui/material/styles';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '12px', // Adds border radius to the dialog
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Custom box shadow for a modern look
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1), // Adds space between icon and text
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: theme.spacing(1, 3), // Custom padding for buttons
  fontWeight: 'bold',
  color: theme.palette.common.white,
}));

const ChatHeader = () => {
  // const { isSidebarOpen, isMobileView, toggleSidebar } = useContext(AppContext);
  const { isSidebarOpen, isMobileView, toggleSidebar, setSidebarOpen, setIsMobileView } = useContext(AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleConfirmLogout = () => {
    dispatch(logout());
    navigate("/");
    setOpenLogoutDialog(false);
    // toast.success("User has logged out");
  };

  const handleSendReport = () => {
    setOpenSummaryDialog(true);
  };

  const handleCloseSummaryDialog = () => {
    setOpenSummaryDialog(false);
  };

  const handleSendSummeryReport = () => {
    const storedUser = localStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : {};
    const userId = user.id;
    toast.success("this is user id", userId);
  };

  const storedUser = localStorage.getItem("user");
  const user = storedUser ? JSON.parse(storedUser) : {};
  const firstName = user?.first_name || "";
  const lastName = user?.last_name || "";
  const userName = firstName + " " + lastName;
  const userType = user?.user_type;



  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#007bff",
          color: "white",
          height: "45px",  // Set fixed height
          position: "sticky",
          top: 0,
          zIndex: 1000,
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ marginBottom: "26px" }}>
            {isMobileView && (
              <IconButton className="sidebar-toggle" onClick={toggleSidebar}>
                {isSidebarOpen ? (<>
                  <CloseIcon />
                </>) : (
                  <>
                    <MenuIcon />
                  </>
                )}

              </IconButton>
            )}
          </div>

          <div style={{ marginTop: "12px", display: "flex", gap: "10px" }}>
            <Avatar
              alt={userName}
              style={{
                borderRadius: "50%",
                width: "40px",
                height: "40px",
              }}
            />
            <span style={{ fontSize: "16px", marginTop: "8px" }}>{userName}</span>
          </div>
        </div>

        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button
            onClick={handleLogoutClick}
            style={{ color: "white", background: "#696a6c94" }}
          >
            Logout
          </Button>
        </Box>

        {/* Logout Confirmation Dialog */}
        <StyledDialog
          open={openLogoutDialog}
          aria-labelledby="logout-dialog-title"
          aria-describedby="logout-dialog-description"
        >
          <StyledDialogTitle id="logout-dialog-title">
            <ExitToAppIcon color="primary" /> Confirm Logout
          </StyledDialogTitle>
          <DialogContent>
            <DialogContentText id="logout-dialog-description">
              Are you sure you want to log out?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <StyledButton onClick={handleCloseDialog} style={{ color: 'white', background: '#6c6b6b' }} variant="contained">
              No
            </StyledButton>
            <StyledButton onClick={handleConfirmLogout} color="primary" variant="contained" autoFocus>
              Yes
            </StyledButton>
          </DialogActions>
        </StyledDialog>

        {/* Summery Report Confirmation Dialog */}
        <Dialog
          open={openSummaryDialog}
          onClose={handleCloseSummaryDialog}
          aria-labelledby="summary-dialog-title"
          aria-describedby="summary-dialog-description"
        >
          <DialogTitle id="summary-dialog-title">Session Report</DialogTitle>
          <DialogContent>
            <DialogContentText id="summary-dialog-description">
              Upon clicking the "Yes" button, a summary report for the specified section will be generated.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSummaryDialog} color="primary">
              No
            </Button>
            <Button onClick={handleSendSummeryReport} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default ChatHeader;
